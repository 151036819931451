<template lang="pug">
.email-view
  .email-view-content
    .email-view-content-title {{ $t('layout.header.contacts.email.title') }}
    .email-view-content-text {{ $t('layout.header.contacts.email.text') }}
  v-link.button.primary-color.light(:to="{ name: 'contact-us' }") {{ $t('layout.header.contacts.email.button') }}
</template>

<script>
export default {
  name: 'EmailViewComponent'
}
</script>

<style lang="sass" scoped>
.email-view
  line-height: 20px
  &-content
    &-title
      font-weight: 700
      margin-bottom: 8px
</style>
